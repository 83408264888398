@font-face {
  font-family: 'Prelo-Bold';
  src: url('./fonts/Prelo-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Prelo-Book';
  src: url('./fonts/Prelo-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Prelo-BookItalic';
  src: url('./fonts/Prelo-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Prelo-Light';
  src: url('./fonts/Prelo-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
